import styled from 'styled-components'
import { between } from 'polished'
import { Link } from 'gatsby'

export const Title = styled.h1`
font-family: 'Lato', sans-serif;
  font-size: ${between("1.602em", "3.998em", "20em", "120em")};
  line-height: ${between("1.8em", "5.653em", "20em", "120em")};
  margin-bottom: 2rem;
  font-weight: 700;
  letter-spacing: 1vw;
  text-transform: uppercase;
`

export const SubTitle = styled.h2`
font-family: 'Lato', sans-serif;
  font-size: ${between("1.424em", "2.827em", "20em", "120em")};
  line-height: ${between("1.602em", "2.03em", "20em", "120em")};
  letter-spacing: 1vw;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1rem;
`
export const PageTitle = styled.h2`
font-family: 'Lato', sans-serif;
  font-size: ${between("1.424em", "2.827em", "20em", "120em")};
  line-height: ${between("1.602em", "2.03em", "20em", "120em")};
  letter-spacing: .5vw;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
`

export const PagSubTitle = styled.h3`
font-family: 'Lato', sans-serif;
  font-size: ${between("1.266em", "1.999em", "20em", "120em")};
  line-height: ${between("1.424em", "2.826em", "20em", "120em")};
  letter-spacing: 1vw;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1.5rem;
`
export const SectionTitle = styled.h3`
font-family: 'Lato', sans-serif;
  font-size: ${between("1.266em", "1.999em", "20em", "120em")};
  line-height: ${between("1.424em", "2.826em", "20em", "120em")};
  letter-spacing: .8vw;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1.5rem;
`
export const SidebarTitle = styled.h3`
font-family: 'Lato', sans-serif;
  font-size: ${between("1.125em", "1.414em", "20em", "120em")};
  line-height: ${between("1.424em", "2.826em", "20em", "120em")};
  letter-spacing: .5vw;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 1.5rem;
`

export const SmallTitle = styled.h4`
font-family: 'Lato', sans-serif;
  font-size: ${between("1em", "1.414em", "20em", "120em")};
  line-height: ${between("1.265em", "1.999em", "20em", "120em")};
  font-weight: 700;
  letter-spacing: .5vw;
  text-transform: uppercase;
  margin-bottom: 4.5rem;
`
export const TagLine = styled.h4`
font-family: 'Lato', sans-serif;
  font-size: ${between("0.707em", "2.998em", "20em", "120em")};
  line-height: ${between("1.8em", "5.653em", "20em", "120em")};
  letter-spacing: .5vw;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  text-align: center;
`

export const TextTitle = styled.h5`
  font-family: 'Lato', sans-serif;
  font-size: ${between(".8em", "1em", "20em", "120em")};
  line-height: ${between("1.7em", "1.7em", "20em", "120em")};
  margin-bottom: 1em;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: 700;
`
export const LinkText = styled(Link)`
font-family: 'Lato', sans-serif;
  text-decoration: none;
  font-size: ${between(".8em", "1em", "20em", "120em")};
  line-height: ${between("1.7em", "1.7em", "20em", "120em")};
  margin-bottom: 1em;
  letter-spacing: .5vw;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: #000;
  transition: color .5s;
  &:hover {
    color: #e6e6e6;
    transition: color .5s;
  }
`
export const Text = styled.p`
  font-size: ${between("1em", "1.2em", "20em", "120em")};
  line-height: ${between("1.7em", "1.7em", "20em", "120em")};
  /* width: ${between("18em", "36em", "20em", "120em")}; */
  font-family: 'Sorts Mill Goudy', serif;
  margin-bottom: 1em;
  @media screen and (min-width: 36em) {
    max-width: 30em;
  }
`

export const Heading = styled(SectionTitle)`
font-family: 'Lato', sans-serif;
  border-left: 3px solid ${props => props.theme.Highlight};
  padding-left: .5em;
  margin-top: 1em;

  @media screen and (min-width: 1000px) {
    border-left: 5px solid ${props => props.theme.Highlight};
  }
`
export const JobTitle = styled.h4`
font-family: 'Lato', sans-serif;
  font-size: .75em;
  color: ${props => props.theme.Highlight};
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.5em;
  margin-bottom: .5em;
`
export const Salary = styled.p`
font-family: 'Lato', sans-serif;
  font-size: .75em;
  color: white !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: .5em;
  line-height: 1.5em;
  text-decoration: none !important;
`
export const Sector = styled.p`
font-family: 'Lato', sans-serif;
  font-size: .75em;
  color: #e6e6e6;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.5em;
`
export const Location = styled(Sector)`
  
`
export const MetaTitle = styled(TextTitle)`
font-family: 'Lato', sans-serif;
  font-size: 0.6em;
  letter-spacing: 4px;
`