import React from 'react'
import Layout from '../components/layout'
import Logo from '../components/MainLogo'
import { SmallTitle, Salary } from '../components/Typography'
import styled from 'styled-components'
import { BoardLogo, ProfessionalLogo, LeadershipLogo} from '../components/Logos/index'

const Grid = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const FlexRow = styled.div`
display: none;
  @media screen and (min-width: 769px) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-items: center;
  margin-bottom: 2em;
  flex-direction: row;
  }


`

const IndexPage = () => (
  <Layout>
    <Grid>
      <Flex>
        <Logo width={'100px'} />
        <SmallTitle style={{ color: '#fff', }}>Website coming soon...</SmallTitle>
      </Flex>
    </Grid>
  </Layout>
)

export default IndexPage
